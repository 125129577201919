import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Like a Hollywood star making the rounds before a premier movie date, Visa and other organizations are going on tour to promote EMV-chip card technology ahead of the October deadline set for U.S. companies.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.paymentssource.com/news/retail-acquiring/visa-steps-up-emv-education-with-city-by-city-tour-3020814-1.html"
      }}>{`Read the Story at PaymentsSource.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      